 export default Alpine.data(
        'selectMultiple',
        (name, initialSelection, model, numCharacters, hasSearch, callbackUrl, text, value, defaultValue, modelName) => ({
            options: [],
            selected: [],
            show: false,
            remoteRequest: Boolean(callbackUrl),
            search: '',
            showSearch: hasSearch,
            text,
            value,
            model,
            initialSelection,
            defaultValue,
            modelName,
            maxWidth: 0,
            async init() {
                window.Livewire.on('resetFilters', () => {
                    this.resetData()
                });

                await this.loadOptions();
                await this.$nextTick()
                this.syncMaxWidth()
                window.addEventListener('resize', () => {
                    this.syncMaxWidth()
                })

                this.mapInitialSelections();
            },
            get groupedOptions() {
                return _.groupBy(this.options, 'group')
            },
            open() {
                this.show = true
                // When opening the box, if search is enabled, focus on the search box
                if (this.showSearch) {
                    this.$nextTick(() => this.$refs.searchBox.focus())
                }
            },
            close() {
                this.show = false
            },
            isOpen() {
                return this.show === true
            },
            mapInitialSelections() {
                if (this.remoteRequest) {
                    this.selected = this.initialSelection.map((selection) => {
                        return {
                            text: selection[this.text],
                            value: selection[this.value]
                        }
                    })
                } else {
                    this.selected = this.initialSelection.map((selection) => {
                        return this.options.find(option => option.value == selection)
                    }).filter((selection) => selection !== undefined)
                }
            },
            async loadOptions(resetToDefault = false) {
                const fetchedOptions = this.remoteRequest ? await this.fetchFromCallbackUrl() : this.fetchFromInputs(resetToDefault);

                this.options = fetchedOptions.map((option, index) => {
                    this.selected.forEach((selection) => {
                        if (parseInt(option.value) === selection.value) {
                            option.selected = true;
                        }
                    })

                    return option;
                });
            },
            fetchFromInputs(resetToDefault) {
                const options = this.$refs.select.options;
                let returnedOptions = [];
                for (let i = 0; i < options.length; i++) {
                    let optionToPush = {
                        value: options[i].value,
                        text: options[i].innerText,
                        group: options[i].dataset.group
                    }

                    if (resetToDefault) {
                        optionToPush.selected = false;
                    } else {
                        const existingOption = this.options.find(option => option.value === optionToPush.value)
                        optionToPush.selected = existingOption?.selected || options[i].getAttribute('selected') != null
                    }

                    returnedOptions.push(optionToPush)
                }

                return returnedOptions;
            },
            async fetchFromCallbackUrl() {
                let result = await axios.get(callbackUrl + '?search=' + this.search + '&disable-pagination=1');
                return result.data.map((item) => {
                    return {
                        text: item[this.text],
                        value: item[this.value],
                        selected: false
                    }
                });
            },
            select(option) {
                const index = this.options.findIndex((arrayOption) => arrayOption.value === option.value)
                if (!this.options[index].selected) {
                    this.options[index].selected = true;
                    this.selected.push(this.options[index]);
                } else {
                    const spliceIndex = this.selected.findIndex(selection => selection.value === this.options[index].value);
                    this.selected.splice(spliceIndex, 1);
                    this.options[index].selected = false
                }

                this.model = this.emittableSelected();
            },
            resetData() {
                this.selected = [];
                this.search = '';
                this.model = [];
                this.loadOptions(true)
                this.close()
            },
            get selectedValues() {
                return this.selected.map(selection => selection.value)
            },
            get selectedNames() {
                return this.selected.map(selection => selection.text)
            },
            get dropdownIsAboveElement() {
                // If the Y value is negative, it means it's above the select element
                return this.$refs.anchorItem?._x_anchor?.y < 0
            },
            // To emit this back to livewire, we need to remap the keys to their original definitions
            emittableSelected() {
                if (this.remoteRequest) {
                    return this.selected.map((selection) => {
                        let returnValue = {};
                        returnValue[this.value] = selection.value;
                        returnValue[this.text] = selection.text;

                        return returnValue;
                    });
                }

                return this.selected.map(selection => selection.value)
            },
            get selectedNamesText() {
                let text = this.selectedNames.join(', ');
                if (text.length === 0) {
                    text = this.defaultValue
                }

                if (numCharacters > 0) {
                    return text.length > numCharacters + 3 ? text.substring(0, numCharacters) + '...' : text
                }

                return text
            },
            async searchOptions() {
                await this.loadOptions()

                if (this.remoteRequest) {
                    return this.options
                }

                this.options = this.options.filter((option) => {
                    return option.text.toLowerCase().includes(this.search.toLowerCase())
                });
            },
            refreshOptions(event) {
                if (event.detail === this.modelName) {
                    this.loadOptions()
                }
            },
            syncMaxWidth() {
                // If this has been closed in the meantime, bail
                if (!this.$refs.button) {
                    return;
                }
                this.maxWidth = this.$refs.button.offsetWidth
                if (this.maxWidth === 0) {
                    window.setTimeout(() => {
                        this.syncMaxWidth()
                    }, 200)
                }
            },
            clearSelections(event) {
                if (event.detail.model === this.modelName) {
                    this.resetData()
                }
            }
        }))
